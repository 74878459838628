import React, { useEffect, useState } from "react";
import Appbar from "../../ui/Appbar";
import { Autocomplete, Box, Button, CircularProgress, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import SideBar from "../../ui/SideBar";
import Body from "../../ui/Body";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllTicketsAPI } from "../../redux/tickets/getAllTickets";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { handleDate } from "../../helpers/date.helper"
import DoneIcon from '@mui/icons-material/Done';
import TicketFeedback from "./TicketFeedback";

function Ticket() {
    const getAllTicketsSelector = useSelector(state => state?.getAllTickets)
    const {result, loading} = getAllTicketsSelector

    const [replyNow, setReplyNow] = useState(false)
    const [feedback, setFeedback] = useState(false)

    const dispatch = useDispatch()

    const handleReplyFeedback = (feedback) => {
        setReplyNow(true)
        setFeedback(feedback)
      }

      const handleChangeClbsStatus = async (e, value, clbId) => {
       
      };
    

  let columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      headerClassName: 'super-app-theme--header',
    },
    {
        field: "description",
        headerName: "Description",
        width: 250,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: "status",
        headerName: "Approved / Rejected",
        width: 200,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return (
            <>
              {params?.row?.status == "Approved" && (
                <Typography variant="p" color="primary">
                  Approved
                </Typography>
              )}
              {params?.row?.status == "Pending" && (
                <Typography variant="p" sx={{ color: "#ffc107" }}>
                  Pending
                </Typography>
              )}
            </>
          );
        },
    },
    {
      field: "reply",
      headerName: "Reply Now",
      width: 250,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <>
            {params?.row?.reply
              ? <Button sx={{ my: 2 }} variant='contained' endIcon={<DoneIcon />}>Replied</Button>
              : <Button sx={{ my: 2 }} onClick={() => handleReplyFeedback(params?.row)} variant='contained' endIcon={<ArrowRightAltIcon />}>Reply Now</Button>
            }


          </>
        )
      }
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 250,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return <Typography>{handleDate(params?.row?.created_at)}</Typography>
      }
    },
  ];


  useEffect(()=>{
    dispatch(getAllTicketsAPI())
  },[])

  return(
     <>
    <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Stack direction='row' alignItems='center'>
              <Typography variant="p">Tickets</Typography>
            </Stack>
          </Box>
        </Paper>

        <Paper
          sx={{ height: '80%', width: '100%' }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
    </Body>
    {replyNow ? <TicketFeedback open={replyNow} setOpen={setReplyNow} feedback={feedback} /> : null }
  </>);
}

export default Ticket;
