import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const getAllAdminNotificationAPI = createAsyncThunk(
    'admin-notifications',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/notification/admin-notifications`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'admin-notifications',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllAdminNotificationAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllAdminNotificationAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(getAllAdminNotificationAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const getAllAdminNotificationReducer = counterSlice.reducer

export default getAllAdminNotificationReducer

