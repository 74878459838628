import { combineReducers } from "redux";
import getAllAdminsReducer from "./admin/getAllAdmins";
import addAssetsReducer from "./assets/addAssets";
import getAssetsReducer from "./assets/getAssets";
import loginReducer from "./auth/login";
import addBannerReducer from "./banner/addBanner";
import changeBannerStatusReducer from "./banner/changeBannerStatus";
import getAllBannersReducer from "./banner/getAllBanners";
import changeCLBStatusReducer from "./clb/changeCLBStatus";
import clbGrowthReducer from "./clb/clbGrowth";
import genrateCLBDetailsReducer from "./clb/genrateCLBDetails";
import getAllCLBsReducer from "./clb/getAllCLBs";
import getCLbDetailsReducer from "./clb/getCLbDetails";
import getTotalCLBsReducer from "./clb/getTotalCLBs";
import mintCLBReducer from "./clb/mintCLB";
import soldCLBsReducer from "./clb/soldCLBs";
import uploadMultipleCLbsReducer from "./clb/uploadMultipleCLbs";
import addRoyaltyFeeReducer from "./config/addRoyaltyFee";
import getAdminsPermissionReducer from "./config/getAdminsPermission";
import getConfigMetaReducer from "./config/getConfigMeta";
import getAllFeedbacksReducer from "./feeback/getAllFeedbacks";
import getFeedbackDetailReducer from "./feeback/getFeedbackDetail";
import sendFeedbackToUserReducer from "./feeback/sendFeedbackToUser";
import addNotificationReducer from "./notification/addNotification";
import getAllAdminNotificationReducer from "./notification/getAllAdminNotification";
import pushNotificationReducer from "./notification/pushNotification";
import getAllOrdersReducer from "./orders/getAllOrders";
import getAllOrgReducer from "./orgnisation/getAllOrg";
import totalTransectionReducer from "./payments/totalTransection";
import asignPermissionToUserReducer from "./permissions/asignPermissionToUser";
import createPermissionModuleReducer from "./permissions/createPermissionModule";
import editPermissionModuleReducer from "./permissions/editPermissionModule";
import getAllPermissionsReducer from "./permissions/getAllPermissions";
import getPermissionModuleDetailsReducer from "./permissions/getPermissionModuleDetails";
import addPrivacyPolicyReducer from "./resource/addPrivacyPolicy";
import addSocialMediaReducer from "./resource/addSocialMedia";
import addTermsConditionsReducer from "./resource/addTermsConditions";
import createAboutUsReducer from "./resource/createAboutUs";
import getResourcesReducer from "./resource/getResources";
import asigneToOrgReducer from "./slb/asigneToOrg";
import getAllSlbsReducer from "./slb/getAllSlbs";
import getAllTicketsReducer from "./tickets/getAllTickets";
import getAllUsersReducer from "./users/getAllUsers";
import getCurrentAdminReducer from "./users/getCurrentAdmin";
import revertSLBsFromOrgReducer from "./slb/revertSLBsFromOrg";
import storeAssetsToIpfsReducer from "./clb/storeAssetsToIpfs";
import transferCLBReducer from "./clb/transferCLB";
import getAllCategoryReducer from "./category/getAllCategory";
import deleteCatReducer from "./category/deleteCat";
import getSingleCatReducer from "./category/getSingleCat";
import editCatReducer from "./category/editCat";
import singleBannerReducer from "./banner/singleBanner";
import editBannerReducer from "./banner/editBanner";
import deleteBannerReducer from "./banner/deleteBanner";
import editNotificationReducer from "./notification/editNotification";
import deleteNotificationReducer from "./notification/deleteNotification";
import singleNotificationReducer from "./notification/singleNotification";
import addAdminReducer from "./admin/addAdmin";
import editAdminReducer from "./admin/editAdmin";
import getSingleAdminReducer from "./users/getSingleAdmin";
import blockUnblockAdminReducer from "./admin/blockUnblockAdmin";
import changeCLBStateReducer from "./clb/changeCLBState";
import downlaodCLBsReducer from "./clb/downlaodCLBs";
import getAllGalleryImgsReducer from "./gallery/getAllGalleryImgs";
import delGallImgReducer from "./gallery/delGallImg";
import editGallImgReducer from "./gallery/editGallImg";
import addGallImgReducer from "./gallery/addGallImg";
import getSingleGallImgReducer from "./gallery/getSingleGallImg";
import getTicketDetailReducer from "./tickets/getTicketDetailAPI";
import sendTicketReplyToUserReducer from "./tickets/sendTicketReplyToUser";
import searchClbReducer from "./clb/searchCLB";
import getAllBlogsReducer from "./Blog/getAllBlogs";
import addBlogReducer from "./Blog/AddBlog";
import deleteBlogAPIReducer from "./Blog/deleteBlogs";
import getSingleBlogAPIReducer from "./Blog/getSingleBlog";
import getSingleBlogCategoryAPIReducer, {
  getSingleBlogCategoryAPI,
} from "./BlogCategory/getSingleBlogCategory";
import editBlogAPIReducer, { editBlogAPI } from "./Blog/editBlog";
import getAllBlogsCategoryReducer from "./BlogCategory/getAllBlogsCategory";
import deleteBlogCategoryAPIReducer from "./BlogCategory/deleteBlogCategory";
import editBlogCategoryAPIReducer from "./BlogCategory/editBlogCategory";
import addUserReducer from "./admin/addUser";
import addOrganizationReducer from "./orgnisation/addOrg";
import getCurrentUserOrgsReducer from "./orgnisation/getCurrentUserOrgs";
import getSlbsForOrgReducer from "./slb/getSlbsForOrg";
import assignSlbstoUserReducer from "./slb/assignSlbsToUser";
import revokeSlbFromUserReducer from "./slb/revokeSlbFromUser";
import getAllFailedApisReducer from "./apiProblems/getAllFailedApis";
import reExecuteFailedApiReducer from "./apiProblems/reExecuteFailedApi";
import changeOrgStatusReducer from "./orgnisation/changeOrgStatus";
import getPriceSliderReducer from "./clb/getPriceSlider";
import getClbBidDetailsReducer from "./clb/getClbBidDetails";
import initiateRefundReducer from "./payments/initiateRefund";

const reducers = combineReducers({
  // resources
  createAboutUs: createAboutUsReducer,
  addSocialMedia: addSocialMediaReducer,
  addPrivacyPolicy: addPrivacyPolicyReducer,
  getResources: getResourcesReducer,
  addTermsConditions: addTermsConditionsReducer,
  // clbs
  getAllCLBs: getAllCLBsReducer,
  uploadMultipleCLbs: uploadMultipleCLbsReducer,
  changeCLBStatus: changeCLBStatusReducer,
  getCLbDetails: getCLbDetailsReducer,
  getTotalCLBs: getTotalCLBsReducer,
  soldCLBs: soldCLBsReducer,
  clbGrowth: clbGrowthReducer,
  mintCLB: mintCLBReducer,
  genrateCLBDetails: genrateCLBDetailsReducer,
  storeAssetsToIpfs: storeAssetsToIpfsReducer,
  transferCLB: transferCLBReducer,
  changeCLBState: changeCLBStateReducer,
  downlaodCLBs: downlaodCLBsReducer,
  searchClb: searchClbReducer,
  getPriceSlider: getPriceSliderReducer,
  getCLbBidDetails: getClbBidDetailsReducer,
  // slbs
  getAllSlbs: getAllSlbsReducer,
  getSlbsForOrg: getSlbsForOrgReducer,
  assignSlbstoUser: assignSlbstoUserReducer,
  revokeSlbFromUser: revokeSlbFromUserReducer,

  //Api problems
  getAllFailedApis: getAllFailedApisReducer,
  reExecuteFailedApi: reExecuteFailedApiReducer,
  // orders
  getAllOrders: getAllOrdersReducer,
  // ngo
  getAllOrg: getAllOrgReducer,
  getCurrentUserOrgs: getCurrentUserOrgsReducer,
  changeOrgStatus: changeOrgStatusReducer,
  addOrg: addOrganizationReducer,
  asigneToOrg: asigneToOrgReducer,
  revertSLBsFromOrg: revertSLBsFromOrgReducer,
  // auth
  login: loginReducer,
  // assets
  addAssets: addAssetsReducer,
  getAssets: getAssetsReducer,
  // users
  getCurrentAdmin: getCurrentAdminReducer,
  // payments
  totalTransection: totalTransectionReducer,
  initiateRefund: initiateRefundReducer,
  // meta
  getConfigMeta: getConfigMetaReducer,
  // users
  getAllUsers: getAllUsersReducer,
  // feedback
  getAllFeedbacks: getAllFeedbacksReducer,
  sendFeedbackToUser: sendFeedbackToUserReducer,
  getFeedbackDetail: getFeedbackDetailReducer,
  // notification
  getAllAdminNotification: getAllAdminNotificationReducer,
  addNotification: addNotificationReducer,
  pushNotification: pushNotificationReducer,
  editNotification: editNotificationReducer,
  deleteNotification: deleteNotificationReducer,
  singleNotification: singleNotificationReducer,
  // config meta
  getAdminsPermission: getAdminsPermissionReducer,
  addRoyaltyFee: addRoyaltyFeeReducer,
  // permissions
  createPermissionModule: createPermissionModuleReducer,
  editPermissionModule: editPermissionModuleReducer,
  getAllPermissions: getAllPermissionsReducer,
  getPermissionModuleDetails: getPermissionModuleDetailsReducer,
  asignPermissionToUser: asignPermissionToUserReducer,
  // admins
  getAllAdmins: getAllAdminsReducer,
  addAdmin: addAdminReducer,
  addUser: addUserReducer,
  editAdmin: editAdminReducer,
  getSingleAdmin: getSingleAdminReducer,
  blockUnblockAdmin: blockUnblockAdminReducer,
  // tickets
  getAllTickets: getAllTicketsReducer,
  getTicketDetail: getTicketDetailReducer,
  sendTicketToUser: sendTicketReplyToUserReducer,
  // banner
  addBanner: addBannerReducer,
  changeBannerStatus: changeBannerStatusReducer,
  getAllBanners: getAllBannersReducer,
  singleBanner: singleBannerReducer,
  editBanner: editBannerReducer,
  deleteBanner: deleteBannerReducer,
  // category
  getAllCategory: getAllCategoryReducer,
  deleteCat: deleteCatReducer,
  getSingleCat: getSingleCatReducer,
  editCat: editCatReducer,
  // gallery
  getAllGalleryImgs: getAllGalleryImgsReducer,
  delGallImg: delGallImgReducer,
  editGallImg: editGallImgReducer,
  getSingleGallImg: getSingleGallImgReducer,
  addGallImg: addGallImgReducer,

  //blogs
  getAllBlogs: getAllBlogsReducer,
  addBlog: addBlogReducer,
  getAllBlogsCategory: getAllBlogsCategoryReducer,
  deleteBlog: deleteBlogAPIReducer,
  getSingleBlog: getSingleBlogAPIReducer,
  getSingleBlogCategory: getSingleBlogCategoryAPIReducer,
  editBlog: editBlogAPIReducer,
  deleteBlogCategory: deleteBlogCategoryAPIReducer,
  editBlogCategory: editBlogCategoryAPIReducer,
  addBlogCategory: editBlogCategoryAPIReducer,
});

export default reducers;
