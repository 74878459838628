import React from 'react';
import MainRoutes from './routes';
import { Toaster } from 'react-hot-toast';

function App() {

  return (
    <>
      <MainRoutes />
      <Toaster />
    </>
  );
}

export default App;
