import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }


export const getAllBlogsAPI = createAsyncThunk(
    'get-All-Blogs',
    async(data, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/blogs/all`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)




const counterSlice = createSlice({
    name: 'get-All-Blogs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllBlogsAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllBlogsAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(getAllBlogsAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})



const getAllBlogsAPIReducer = counterSlice.reducer

export default getAllBlogsAPIReducer