import { Box, Button, Card, Modal, Stack, Typography } from "@mui/material";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCatAPI } from "../../redux/category/deleteCat";
import Axios from "../../config/authAxios";

import { getAllCategoryAPI } from "../../redux/category/getAllCategory";

function DeleteTicker({ open, setOpen, deleteFun }) {
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 200, borderRadius:3 }}>
            <Stack spacing={2}>
              <Typography variant="h6">Delete Ticker</Typography>

              <Stack direction="row" spacing={2}>
                <Button
                  onClick={deleteFun}
                  type="submit"
                  variant="contained"
                >
                  Delete
                </Button>
                <Button onClick={handleClose} variant="contained" color="error">
                  Close
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteTicker;
