const { default: Axios } = require("../config/authAxios")

class UserService {
    getAllVerifiedUsers = ({ sort }) => Axios.get(`/users?sort=${sort}`);
    createAdmin = (body) => Axios.post("/admin/create/admin", { ...body });
    getAllAdmins = () => Axios.get("/admin");
    editAdminUser = (id, body) => Axios.put("/admin/edit-admin/" + id, { ...body });
    deleteAdminUser = (id) => Axios.delete("/admin/" + id);
    getCurrentUser = () => Axios.get("/admin/own");
    getUserAttributes = () => Axios.get("/admin/attributes")
}

export const userService = new UserService()

