import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"


const initialState = { loading: false, result: [], error: null, message: ""}

export const getTicketDetailAPI = createAsyncThunk(
    'get-ticket-detail',
    async(data,{ rejectWithValue }) =>{
        console.log("id=======>",data)
        try {
            const response = await Axios.get(`/ticket/${data}`)
            //console.log(response)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


const counterSlice = createSlice({
    name: 'get-ticket-detail',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getTicketDetailAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getTicketDetailAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(getTicketDetailAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})



const getTicketDetailReducer = counterSlice.reducer

export default getTicketDetailReducer
