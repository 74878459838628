const { default: Axios } = require("../config/authAxios")

class CategoryService {
    getAllCategory = () => {
        return Axios.get("/category")
    }
    create = (body) => {
        return Axios.post("/category", { ...body })
    }
    edit = (id, body) => {
        return Axios.put("/category/" + id, { ...body })
    }
    detele = (id) => {
        return Axios.delete("/category/" + id)
    }
}

export const categoryService = new CategoryService()

