import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGalleryImgsAPI } from "../../redux/gallery/getAllGalleryImgs";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import AddGallery from "./AddGallery";
import DeleteGallery from "./DeleteGallery";
import EditGallery from "./EditGallery";
import { CloudUpload } from "@mui/icons-material";

function Gallery() {
  const getAllGalleryImgsSelector = useSelector(
    (state) => state?.getAllGalleryImgs
  );
  const { result, loading } = getAllGalleryImgsSelector;

  const dispatch = useDispatch();

  const [addGall, setAddGall] = useState(false);
  const [galleryId, setGalleryId] = useState(false);
  const [editGall, setEditGall] = useState(false);
  const [delGall, setDelGall] = useState(false);

  let columns = [
    {
      field: "image",
      headerName: "Image",
      width: 500,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <span style={{ padding: "10px" }}>
            <img src={params?.row?.image} alt="gallery image" />
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "edit_gallery",
      headerName: "Edit Gallery",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="info"
              onClick={() => handleEditGallery(params?.row?._id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_gallery",
      headerName: "Delete Gallery",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleDeleteGallery(params?.row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleEditGallery = async (galleryId) => {
    setGalleryId(galleryId);
    setEditGall(true);
  };

  const handleDeleteGallery = async (galleryId) => {
    setGalleryId(galleryId);
    setDelGall(true);
  };

  useEffect(() => {
    // alert("adsf");
    dispatch(getAllGalleryImgsAPI());
  }, [dispatch]);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="p">Gallery</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              {/* <Button
                onClick={() => setAddGall(true)}
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Add
              </Button> */}
              <Button variant="outlined" onClick={() => setAddGall(true)}>
                  Add Gallery<CloudUpload sx={{ ml: 1 }} />
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>

      {addGall && <AddGallery open={addGall} setOpen={setAddGall} />}
      {editGall && (
        <EditGallery
          open={editGall}
          setOpen={setEditGall}
          galleryId={galleryId}
        />
      )}
      {delGall && (
        <DeleteGallery
          open={delGall}
          setOpen={setDelGall}
          galleryId={galleryId}
        />
      )}
    </>
  );
}

export default Gallery;
