import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

const PopperButton = ({
  buttonClassName,
  buttonContent,
  popperClassName,
  popperBody,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });
  const [open, setOpen] = useState(false);
  const popperRef = useRef();

  // Close the popper when clicking outside
  const handleClickOutside = (event) => {
    if (popperRef.current && !popperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Attach the click event listener on mount and clean it up on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        type="button"
        className={buttonClassName}
        ref={setReferenceElement}
        onClick={() => setOpen(!open)}
      >
        {buttonContent}
      </button>

      {open && (
        <div
          className={`z-50 shadow-lg left-7  ${popperClassName}`}
          ref={(el) => {
            setPopperElement(el);
            popperRef.current = el; // Assigning to the ref for outside click detection
          }}
          style={styles.popper}
          {...attributes.popper}
        >
          {popperBody}
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </>
  );
};

export default PopperButton;
