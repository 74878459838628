import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CollectionsIcon from "@mui/icons-material/Collections";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { AiOutlineApi, AiOutlineFileText } from "react-icons/ai";
import { RxSlider } from "react-icons/rx";
import { IoLogOut, IoNotificationsOutline, IoShareSocialOutline } from "react-icons/io5";
import { MdNoEncryptionGmailerrorred, MdOutlineCategory, MdOutlinePrivacyTip, MdOutlineSpaceDashboard, MdPeopleOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Style from "../styles/sidebar.module.scss";
import Logo from "../assets/png/logo.png";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { VscFeedback } from "react-icons/vsc";
import { FaBlog } from "react-icons/fa";
import { BsFillCartCheckFill, BsTicket } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";


export default function SideBar() {
  const getCurrentAdminSelector = useSelector(
    (state) => state?.getCurrentAdmin
  );
  const { result } = getCurrentAdminSelector;
  const permissions = result?.permissionId?.permissions;
  const role = result?.role;
  const { pathname } = useLocation();

  const menu = [];
  menu.push({
    name: "Dashboard",
    url: "/",
    icon: (
      <DashboardIcon
        className={pathname === "/" ? Style.active_icon : Style.inactive_icon}
      />
    ),
    default: true,
  });
  if (role == "SuperAdmin" || permissions?.includes("AdminUsers"))
    menu.push({
      name: "Admin Users",
      url: "/users/admin",
      icon: (
        <GroupIcon
          className={
            pathname === "/users/admin"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Permissions"))
    menu.push({
      name: "Permissions",
      url: "/users/permissions",
      icon: (
        <MdNoEncryptionGmailerrorred
          className={
            pathname === "/users/permissions"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Users"))
    menu.push({
      name: "Users",
      url: "/users",
      icon: (
        <AdminPanelSettingsIcon
          className={
            pathname === "/users" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Assets"))
    menu.push({
      name: "Assets",
      url: "/assets",
      icon: (
        <AiOutlineFileText
          className={
            pathname === "/assets" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("CLBs"))
    menu.push({
      name: "CLBs",
      url: "/clbs",
      icon: (
        <AiOutlineFileText
          className={
            pathname === "/clbs" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Orders"))
    menu.push({
      name: "Orders",
      url: "/orders",
      icon: (
        <BsFillCartCheckFill
          className={
            pathname === "/orders" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Gallery"))
    menu.push({
      name: "Gallery",
      url: "/gallery",
      icon: (
        <CollectionsIcon
          className={
            pathname === "/gallery" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Category"))
    menu.push({
      name: "Category",
      url: "/category",
      icon: (
        <MdOutlineCategory
          className={
            pathname === "/category" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Organization"))
    menu.push({
      name: "Organization",
      url: "/organization",
      icon: (
        <MdOutlineSpaceDashboard
          className={
            pathname === "/organization"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Banner"))
    menu.push({
      name: "Banner",
      url: "/banner",
      icon: (
        <ViewCarouselIcon
          className={
            pathname === "/banner" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("SLBs"))
    menu.push({
      name: "SLBs",
      url: "/slbs",
      icon: (
        <MdOutlineSpaceDashboard
          className={
            pathname === "/slbs" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });

  // TODO: Create Assign SLBs feature
  if (role === "SuperAdmin" || permissions?.includes("Assign SLBs"))
    menu.push({
      name: "Assign SLBs",
      url: "/assign-slbs",
      icon: (
        <MdOutlineSpaceDashboard
          className={
            pathname === "/assign-slbs" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });

  if (role === "SuperAdmin" || permissions?.includes("API Failures"))
    menu.push({
      name: "API Failures",
      url: "/api-failures",
      icon: (
        <AiOutlineApi
          className={
            pathname === "/api-failures"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });

  if (role == "SuperAdmin" || permissions?.includes("Ticker"))
    menu.push({
      name: "Ticker",
      url: "/ticker",
      icon: (
        <RxSlider
          className={
            pathname === "/ticket" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Feedback"))
    menu.push({
      name: "Feedback",
      url: "/feedback",
      icon: (
        <VscFeedback
          className={
            pathname === "/feedback" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("PushNotification"))
    menu.push({
      name: "Push Notifications",
      url: "/notifications",
      icon: (
        <IoNotificationsOutline
          className={
            pathname === "/notifications"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("AboutUs"))
    menu.push({
      name: "About Us",
      url: "/about-us",
      icon: (
        <MdPeopleOutline
          className={
            pathname === "/about-us" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("SocialMediaLinks"))
    menu.push({
      name: "Social Media Links",
      url: "/social-media",
      icon: (
        <IoShareSocialOutline
          className={
            pathname === "/social-media"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("PrivacyPolicy"))
    menu.push({
      name: "Terms & Condition",
      url: "/terms-conditions",
      icon: (
        <MdOutlinePrivacyTip
          className={
            pathname === "/terms-conditions"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Users"))
    menu.push({
      name: "Privacy Policy",
      url: "/privacy-policy",
      icon: (
        <MdOutlinePrivacyTip
          className={
            pathname === "/privacy-policy"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Tickets"))
    menu.push({
      name: "Tickets",
      url: "/tickets",
      icon: (
        <BsTicket
          className={
            pathname === "/tickets" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("Blogs"))
    menu.push({
      name: "Blogs",
      url: "/blogs",
      icon: (
        <FaBlog
          className={
            pathname === "/blogs" ? Style.active_icon : Style.inactive_icon
          }
        />
      ),
    });
  if (role == "SuperAdmin" || permissions?.includes("BlogsCategory"))
    menu.push({
      name: "Blogs Category",
      url: "/blogsCategory",
      icon: (
        <BiCategoryAlt
          className={
            pathname === "/blogsCategory"
              ? Style.active_icon
              : Style.inactive_icon
          }
        />
      ),
    });
  menu.push({
    name: "Logout",
    url: "/logout",
    icon: (
      <IoLogOut
        className={
          pathname === "/logout" ? Style.active_icon : Style.inactive_icon
        }
      />
    ),
  });

  return (
    <Box className={Style.sidebar}>
      <Link to="/">
        <Box className={Style.logo}>
          <img src={Logo} alt="logo" width="50%" />
        </Box>
      </Link>
      <List>
        {menu?.map((item, index) => (
          <Link to={item?.url}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                className={
                  item?.url === pathname
                    ? Style.activeLink
                    : Style.nonActiveLink
                }
              >
                <ListItemIcon>{item?.icon}</ListItemIcon>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: item?.url === pathname ? "#FFFFFF" : "#999999",
                  }}
                >
                  {item?.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
}
