import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const addRoyaltyFeeAPI = createAsyncThunk(
    'add-royaltyFee',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.patch(`/meta/add-royaltyFee`, data)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'add-royaltyFee',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(addRoyaltyFeeAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addRoyaltyFeeAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(addRoyaltyFeeAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const addRoyaltyFeeReducer = counterSlice.reducer

export default addRoyaltyFeeReducer

