import { Autocomplete, Box, Button, Card, Modal, Stack, TextField, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminsAPI } from "../../redux/admin/getAllAdmins";
import { asignPermissionToUserAPI } from "../../redux/permissions/asignPermissionToUser";

import { getAllPermissionsAPI } from "../../redux/permissions/getAllPermissions";


function AddAdminPermission({ open, setOpen, adminId }) {

    const getAllPermissionsSelector = useSelector(state => state?.getAllPermissions)
    const { result } = getAllPermissionsSelector

    const getSingleAdminSelector = useSelector(state => state?.getSingleAdmin)
    const { result: singleAdminResult } = getSingleAdminSelector

    const asignPermissionToUserSelector = useSelector(state => state?.asignPermissionToUser)
    const { message } = asignPermissionToUserSelector
    const dispatch = useDispatch()


    const [permissionId, setPermissionId] = useState('')
    const [defaultPermission, setDefaultPermission] = useState('')


    const handleAsignPermissionToAdmin = async (data) => {
        await dispatch(asignPermissionToUserAPI({ adminId, permissionId: permissionId }))
        await dispatch(getAllAdminsAPI())
        setOpen(false)

    }

    const handleClose = () => setOpen(false)


    useEffect(() => {
        setDefaultPermission(singleAdminResult?.permissionId?.name)
    }, [defaultPermission, singleAdminResult]);


    useEffect(() => {
        dispatch(getAllPermissionsAPI())
    }, [])

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 2, width: 600 }}>

                        <Stack spacing={2}>
                            <Typography variant='h6'>Assign Permission!</Typography>
                            <Autocomplete
                                getOptionSelected={defaultPermission}
                                disablePortal
                                options={result}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                onChange={(event, val) => {
                                    setPermissionId(val?._id)
                                }}
                                renderInput={(params) => <TextField {...params} label="Assign Permission" />}
                            />
                            <Stack direction='row' spacing={2}>
                                <Button onClick={handleAsignPermissionToAdmin} variant="contained">Assign</Button>
                                <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                            </Stack>
                        </Stack>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

export default AddAdminPermission;

