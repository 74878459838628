import { Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

export default function RefundWalletAddressModal({
  open,
  setOpen,
  handleSubmit,
  setWalletAddress,
}) {
  const handleClose = () => setOpen(false);
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box className="confirmeBox">
          <DialogTitle variant="h4" color="primary">
            Refund Address Wallet
          </DialogTitle>
          <DialogContent>
            <TextField
              sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
              label="Wallet Address"
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit} autoFocus>
              Create Refund
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
