import React, { useState, useEffect } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const DisplayOrgDocumentsModal = ({ files, open, setOpen }) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  // Function to classify files based on their mimetype
  const classifyFiles = (files) => {
    const imageFiles = [];
    const pdfFiles = [];

    files.forEach((file) => {
      if (file?.mimetype?.startsWith("image")) {
        imageFiles.push(file);
      } else if (file?.mimetype === "application/pdf") {
        pdfFiles.push(file);
      }
    });

    return { imageFiles, pdfFiles };
  };

  // Classify the files
  const { imageFiles, pdfFiles } = classifyFiles(files);

  useEffect(() => {
    // Set the first image file as selected by default when modal is opened
    if (open && imageFiles.length > 0) {
      setSelectedFile(imageFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setSelectedFile(null); // Reset selected file when changing tabs
    const filesToDisplay = newValue === 0 ? imageFiles : pdfFiles;
    if (filesToDisplay.length > 0) {
      setSelectedFile(filesToDisplay[0]);
    }
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 1000,
          height: 600,
          display: "flex",
          bgcolor: "background.paper",
          p: 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            borderRight: "1px solid #ccc",
            overflow: "hidden",
          }}
        >
          <Typography variant="h5">Documents</Typography>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="file tabs"
          >
            <Tab label="Images" />
            <Tab label="PDF Files" />
          </Tabs>
          <List
            sx={{
              maxHeight: 500, // Adjust the max height of the list as needed
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#c8c6c6",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#807f7f",
              },
            }}
          >
            {(tabValue === 0 ? imageFiles : pdfFiles).map((file, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleFileClick(file)}
                sx={{
                  backgroundColor:
                    selectedFile &&
                    selectedFile.originalname === file.originalname
                      ? "#dbf2f3"
                      : "transparent",
                }}
              >
                <ListItemText primary={file.originalname} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            flex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 20px",
            overflowY: "auto",
          }}
        >
          {selectedFile && selectedFile.mimetype === "application/pdf" ? (
            <object
              data={selectedFile.link}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <embed src={selectedFile.link} type="application/pdf" />
            </object>
          ) : (
            selectedFile && (
              <img
                src={selectedFile.link}
                alt={selectedFile.originalname}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "block",
                  margin: "auto",
                }}
              />
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DisplayOrgDocumentsModal;
