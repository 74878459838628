import React from "react";

function HeadingBar({ title, element }) {
  return (
    <div className="w-full mb-6">
      <div className="heading-text-color w-full bg-white-background items-center p-2 gap-2 flex font-bold rounded-lg">
        <div className="flex p-3 w-full items-center gap-2">
          <div>{title}</div>
        </div>
        <div className="align-right h-full  flex justify-end w-full">
          {element}
        </div>
      </div>
    </div>
  );
}

export default HeadingBar;
