const { default: Axios } = require("../config/authAxios")

class FileUploader {
    single = file => {
        const formData = new FormData();
        // Store form name as "file" with file data
        formData.append("file", file, file.name);
        return Axios.post("/file/upload/single", formData)
    }

    multiple = files => {
        const multipleFormData = [];
        const formData = new FormData();
        // Store form name as "file" with file data
        for (let file of files) formData.append("files", file, file.name)
        // formData.append("files", files);
        return Axios.post("/file/upload/multiple", formData)
    }
}

export const fileUploader = new FileUploader()
export default fileUploader

