import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios";

const initialState = { loading: false, result: [], error: null, message: "" };

export const getAllFailedApisAPI = createAsyncThunk(
  "get-all-failed-apis",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/admin/api-failures`);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "get-all-failed-apis",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFailedApisAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllFailedApisAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        // state.message = action.payload?.message
      })
      .addCase(getAllFailedApisAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const getAllFailedApisReducer = counterSlice.reducer;

export default getAllFailedApisReducer;
