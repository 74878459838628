import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBannerStatusAPI } from "../../redux/banner/changeBannerStatus";
import { getAllBannersAPI } from "../../redux/banner/getAllBanners";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";

import AddBanner from "./AddBanner";
import EditBanner from "./EditBanner";
import DeleteBanner from "./DeleteBanner";
import DoneIcon from "@mui/icons-material/Done";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

function Banner(props) {
  const getAllBannersSelector = useSelector((state) => state?.getAllBanners);
  const { result, loading } = getAllBannersSelector;

  const changeBannerStatusSelector = useSelector(
    (state) => state?.changeBannerStatus
  );
  const { message, error } = changeBannerStatusSelector;

  const dispatch = useDispatch();

  const [addBanner, setAddbanner] = useState(false);

  const [editBanner, setEditBanner] = useState(false);
  const [delBanner, setDelBanner] = useState(false);
  const [bannerId, setBannerId] = useState("");

  let columns = [
    {
      field: "banner",
      headerName: "Image",
      width: 700,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <img
            style={{ margin: "10px" }}
            src={params?.row?.banner}
            alt="banner image"
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <Box sx={{ py: 1 }}>
              {params?.row?.status == "InActive" && (
                <Button
                  onClick={() =>
                    handleChangeStatusOfBanner("Active", params?.row?._id)
                  }
                  variant="contained"
                  endIcon={<ArrowRightAltIcon />}
                >
                  In Active
                </Button>
              )}
              {params?.row?.status == "Active" && (
                <Button
                  onClick={() =>
                    handleChangeStatusOfBanner("InActive", params?.row?._id)
                  }
                  variant="contained"
                  endIcon={<DoneIcon />}
                >
                  Active
                </Button>
              )}
            </Box>
          </>
        );
      },
    },
    {
      field: "edit_permission",
      headerName: "Edit Banner",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="info"
              onClick={() => handleEditCategory(params?.row?._id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_permission",
      headerName: "Delete Banner",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteCategory(params?.row?._id)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleEditCategory = async (bannerId) => {
    setEditBanner(true);
    setBannerId(bannerId);
  };

  const handleDeleteCategory = async (bannerId) => {
    setDelBanner(true);
    setBannerId(bannerId);
  };

  const handleAddbanner = () => setAddbanner(true);

  const handleChangeStatusOfBanner = async (status, bannerId) => {
    await dispatch(changeBannerStatusAPI({ status, bannerId }));
    await dispatch(getAllBannersAPI());
  };

  useEffect(() => {
    dispatch(getAllBannersAPI());
  }, []);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="p">Banner</Typography>
            </Stack>
            <Box sx={{ display: "flex" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button variant="outlined" onClick={handleAddbanner}>
                  Add Banner
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Paper>

        <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>
      {addBanner && <AddBanner open={addBanner} setOpen={setAddbanner} />}
      {editBanner && (
        <EditBanner
          open={editBanner}
          setOpen={setEditBanner}
          bannerId={bannerId}
        />
      )}
      {delBanner && (
        <DeleteBanner
          open={delBanner}
          setOpen={setDelBanner}
          id={bannerId}
        />
      )}
    </>
  );
}

export default Banner;
