import { CircularProgress } from '@mui/material';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Month'
            }
        },
        y: {
            title: {
                display: true,
                text: 'Clbs'
            }
        }
    }
};


export const GrowthGraph = ({ result, loading }) => {

    const data = {
        labels: [...result?.map(el => el.month)],
        datasets: [
            {
                label: 'CLbs Sold Monthise',
                data: [...result?.map(el => el.clbs)],
                backgroundColor: '#35ABAF',
            },
        ],
    };
    return (
        <>
            {loading
                ? <CircularProgress />
                : <Bar options={options} data={data} height={80} />
            }
        </>
    );
}
