import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getTicketDetailAPI } from "../../redux/tickets/getTicketDetailAPI";
import { sendTicketReplyToUserAPI } from "../../redux/tickets/sendTicketReplyToUser";
import { getAllTicketsAPI } from "../../redux/tickets/getAllTickets";


const schema = yup.object({
    subject: yup.string().required("required field"),
    reply: yup.string().required("required field"),
}).required();

function TicketFeedback({open, setOpen, feedback}) {
     const getTicketDetailAPISelector = useSelector(store => store?.getTicketDetail)
     const {result} = getTicketDetailAPISelector

    const dispatch = useDispatch()

    const {register, handleSubmit, setError, formState: { errors }, reset } = useForm({
        resolver:yupResolver(schema)
    })
    const handleClose = () => setOpen(false)

    const handleTicketFeedback = async (data) =>{
        await dispatch(sendTicketReplyToUserAPI({...data, ticketId: feedback?._id, userEmail: feedback?.email }))
        await dispatch(getAllTicketsAPI())
        setOpen(false)
    }

    useEffect(() =>{
        dispatch(getTicketDetailAPI(feedback?._id))
    },[])

    useEffect(()=>{
        let defaultValues = {};
        defaultValues = {
            subject: result?.subject
        }
        reset({ ...defaultValues });  
    },[result])

  return <>
    <Modal open={open} onClose={handleClose}>
        <Box className='modal'>
            <Card sx={{ p: 2, width: 600 }}>
                 <form onSubmit={handleSubmit(handleTicketFeedback)}>
                     <Stack spacing={2}>
                           <Typography>Reply</Typography>
                           <TextField label="Subject" disabled {...register('subject')} error={errors?.subject?.message} helperText={errors?.subject?.message}/>
                           <TextField label="Reply" {...register('reply')}  error={errors?.reply?.message} helperText={errors?.reply?.message} />
                           <Stack direction='row' justifyContent='flex-end' spacing={2}>
                                    <Button variant='outlined' onClick={handleClose}>Back</Button>
                                    <Button variant='contained' type='submit'>Send</Button>
                           </Stack>
                     </Stack>
                 </form>
            </Card>
        </Box>
    </Modal>
  </>;
}

export default TicketFeedback;
