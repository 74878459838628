import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridSearchIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changeCLBStatusAPI } from "../../redux/clb/changeCLBStatus";
import { getAllCLBsAPI } from "../../redux/clb/getAllCLBs";
import Style from "../../styles/clb.module.scss";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import ConfimeMintCLB from "./ConfimeMintCLB";
import sampleCSV from "./smaple";
import { getAllCategoryAPI } from "../../redux/category/getAllCategory";
import { changeCLBStateAPI } from "../../redux/clb/changeCLBState";
import { downlaodCLBsAPI } from "../../redux/clb/downlaodCLBs";
import { Image, SearchOutlined } from "@mui/icons-material";
import { searchClbAPI } from "../../redux/clb/searchCLB";
import { getPriceSliderAPI } from "../../redux/clb/getPriceSlider";
import BidPriceDetailsModal from "./BidPriceDetailsModal";
import { initiateRefundAPI } from "../../redux/payments/initiateRefund";
import RefundWalletAddressModal from "./RefundWalletAddress";

function CLBs(props) {
  const [openBidDetail, setOpenBidDetail] = useState(false);
  const [selectedClb, setSelectedClb] = useState();

  const getAllCLBsSelector = useSelector((state) => state?.getAllCLBs);
  const { result, loading } = getAllCLBsSelector;

  const getAllCategorySelector = useSelector((state) => state?.getAllCategory);
  const { result: categories } = getAllCategorySelector;

  const downlaodCLBsSelector = useSelector((state) => state?.downlaodCLBs);
  const { result: downloadCLB } = downlaodCLBsSelector;

  const searchClbSelector = useSelector((state) => state?.searchClb);
  const { result: searchResult } = searchClbSelector;

  const getPriceSliderSelector = useSelector((state) => state?.getPriceSlider);
  const { result: priceSliderResult } = getPriceSliderSelector;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    topBid: false,
    topCollectors: false,
    priceRange: [priceSliderResult?.min, priceSliderResult?.max],
    category: "",
  });

  const [CLBDetails, setCLBDetails] = useState({});
  const [mintCLB, setMintCLB] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const openFilterSort = Boolean(anchorElSort);
  const [sortFilter, setSortFilter] = useState("");
  const [category, setCategory] = useState({ type: null, id: null });
  const [typeCLB, setTypeCLB] = useState(null);
  const [searchTxt, setSearchTxt] = useState(null);
  const [refundCLB, setRefundCLB] = useState(false);
  const [refundCLBId, setRefundCLBId] = useState("");
  const [refundWalletAddress, setRefundWalletAddress] = useState(null);
  let isBidAble = null;

  let columns = [
    {
      field: "title",
      headerName: "Title",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Link to={`${params?.row?._id}/details`}>
            <Typography variant="p" color="primary">
              {params?.row?.title}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: "category.title",
      headerName: "Category",
      width: 100,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.category?.title}</Typography>
        );
      },
    },
    {
      field: "creator.name",
      headerName: "Creator",
      width: 100,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.creator?.name}</Typography>
        );
      },
    },
    {
      field: "url_assets",
      headerName: "Asset",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.type == "2d" && (
              <Box sx={{ p: 4, width: 150 }}>
                <img
                  src={params?.row?.files?.[0]}
                  width={"100%"}
                  height={"100%"}
                  alt="CLB"
                />
              </Box>
            )}
            {params?.row?.type == "3d" && (
              <model-viewer
                style={{ width: "100%", height: "200px" }}
                src={params?.row?.files?.[0]}
                ar-modes="webxr scene-viewer quick-look"
                camera-controls
                poster="poster.webp"
                shadow-intensity="1"
                disable
              ></model-viewer>
            )}
          </>
        );
      },
    },
    {
      field: "url",
      headerName: "Asset Url",
      width: 600,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <Link
              style={{ textDecoration: "underline" }}
              to={params?.row?.files?.[0]}
            >
              <Typography variant="subtitle2" color="primary">
                {params?.row?.files?.[0]}
              </Typography>
            </Link>
          </Box>
        );
      },
    },
    {
      field: "active",
      headerName: "Status",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.active ? (
              <Typography variant="p" color="primary">
                Active
              </Typography>
            ) : (
              <Typography variant="p" color="error">
                In Active
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "active_inactive",
      headerName: "Active / In Active",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <Autocomplete
              fullWidth
              size="small"
              onChange={(e, value) =>
                handleChangeCLBState(e, value, params?.row?._id)
              }
              disablePortal
              options={["Active", "In Active"]}
              value={params?.row?.active ? "Active" : "In Active"}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </>
        );
      },
    },
    {
      field: "Seller",
      headerName: "Seller",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.creator?.name}</Typography>
        );
      },
    },
    {
      field: "Buyer",
      headerName: "Buyer",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "isBidAble",
      headerName: "Is Bidable",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.isBidAble ? (
              <Typography variant="p" color="primary">
                Bidable
              </Typography>
            ) : (
              <Typography variant="p" color="error">
                Non Bidable
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "cost.inr",
      headerName: "Sale Price (INR)",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.cost?.inr}</Typography>;
      },
    },
    {
      field: "Sale",
      headerName: "Sale Price (USD)",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.cost?.usd}</Typography>;
      },
    },
    // {
    //   field: "inr",
    //   headerName: "Bid Price (INR)",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    // },

    {
      field: "inr",
      headerName: "Bid Price",
      width: 250,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        if (
          /* params?.row?.documents && params?.row?.documents.length !== 0 */ true
        ) {
          return (
            <>
              <Button
                onClick={() => {
                  // const currUser = dataResult.find(
                  //   (usr) => usr?._id === params?.row?._id
                  // );

                  // const userDocuments = currUser ? currUser?.documents : [];
                  // setCurrentDoc(userDocuments);
                  // setOpenDocumentsModal(true);

                  setSelectedClb(params?.row);

                  setOpenBidDetail(true);
                }}
                startIcon={<VisibilityIcon fontSize="small" />}
                variant="outlined"
                size="small"
              >
                View
              </Button>
            </>
          );
        } else {
          return <Typography variant="body2">Not uploaded</Typography>;
        }
      },
    },

    // {
    //   field: "Bid",
    //   headerName: "Bid Price (USD)",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "royaltyFee.percentage",
      headerName: "Royalty Fee Percentage",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{`${
            params?.row?.royaltyFee?.percentage || "--"
          } %`}</Typography>
        );
      },
    },
    {
      field: "royaltyFee.INR",
      headerName: "Royalty Fee (INR)",
      width: 200,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.royaltyFee?.INR}</Typography>
        );
      },
    },
    {
      field: "royaltyFee.USD",
      headerName: "Royalty Fee (USD)",
      width: 200,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.royaltyFee?.USD}</Typography>
        );
      },
    },
    {
      field: "account_address",
      headerName: "NFT Address",
      width: 300,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.nft?.address}</Typography>;
      },
    },
    {
      field: "approve_rejected_status",
      headerName: "Approval status",
      width: 200,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.status == "Approved" && (
              <Typography variant="p" color="primary">
                Approved
              </Typography>
            )}
            {params?.row?.status == "Rejected" && (
              <Typography variant="p" color="error">
                Rejected
              </Typography>
            )}
            {params?.row?.status == "Pending" && (
              <Typography variant="p" sx={{ color: "#ffc107" }}>
                Pending
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "approve_rejected",
      headerName: "Approved / Rejected",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Autocomplete
              fullWidth
              size="small"
              onChange={(e, value) =>
                handleChangeClbsStatus(e, value, params?.row)
              }
              disablePortal
              options={["Approved", "Rejected"]}
              value={params?.row?.status}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </>
        );
      },
    },
    {
      field: "mint",
      headerName: "Mint NFT",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.mint ? (
              <Button
                sx={{ my: 2 }}
                color="success"
                variant="contained"
                endIcon={<DoneIcon />}
              >
                Minted
              </Button>
            ) : (
              <Button
                sx={{ my: 2 }}
                color="error"
                variant="contained"
                onClick={() => handleMintCLB(params?.row)}
                disabled={params.row?.status !== "Approved"}
              >
                Mint now
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const handleChangeClbsStatus = async (e, value, clb) => {
    if (value === "Rejected") {
      setRefundCLB(true);
      setRefundCLBId(clb._id);
      return;
    }
    await dispatch(changeCLBStatusAPI({ clbId: clb?._id, status: value }));
    await dispatch(getAllCLBsAPI({ sortFilters: sortFilter, ...filters }));
    if (value === "Approved") handleMintCLB(clb);
  };

  const handleMintCLB = (CLB) => {
    setCLBDetails(CLB);
    setMintCLB(true);
  };

  const handleRejectCLB = async () => {
    await dispatch(
      initiateRefundAPI({ clbId: refundCLBId, refundWalletAddress })
    );
    setRefundCLB(false);
    await dispatch(
      changeCLBStatusAPI({ clbId: refundCLBId, status: "Rejected" })
    );
    await dispatch(getAllCLBsAPI({ sortFilters: sortFilter, ...filters }));
  };

  const handleFilterToggle = (event) => setAnchorEl(event.currentTarget);
  const handleCloseFilter = () => setAnchorEl(null);
  const handleFilterToggleSort = (event) =>
    setAnchorElSort(event.currentTarget);
  const handleCloseFilterSort = () => setAnchorElSort(null);

  const handleExploreCLBsFilters = (event, idx) => {
    let filter = {
      topBid: false,
      topCollectors: false,
      priceRange: [priceSliderResult?.min, priceSliderResult?.max],
      category: "",
    };

    const name = event?.target?.name;
    const value = event?.target?.value;
    const checked = event?.target?.checked;
    const Id = event?.target?.id;

    name === "bid" && setTypeCLB(value);

    if (Id == "topBid") filter = { ...filter, topBid: true };
    if (Id == "topCollectors") filter = { ...filter, topCollectors: true };
    if (name == "priceRange") filter = { ...filter, priceRange: value };
    if (name == "category") {
      if (checked) filter = { ...filter, category: value?._id };
      else filter = { ...filter, category: "" };
    }
    if (value == "onSale")
      filter = {
        ...filters,
        others: { ...filters?.others, onSale: checked },
      };
    if (value == "bid") filter = { ...filters, isBidAble: true };
    if (value == "purchase") filter = { ...filters, isBidAble: false };

    setFilters({
      ...filter,
      sortFilters: sortFilter,
      title: searchTxt,
      category: category.id,
    });
  };

  const handleCatFilter = async (type, catId) => {
    setCategory({ type, id: catId });
    console.log("catId", catId);
    dispatch(
      getAllCLBsAPI({
        ...filters,
        category: catId,
        sortFilters: sortFilter,
        title: searchTxt,
      })
    );
  };

  const handleSortFilters = async (type) => {
    dispatch(
      getAllCLBsAPI({
        ...filters,
        category: category.id,
        sortFilters: type,
        title: searchTxt,
      })
    );
    setSortFilter(type);
  };

  const handleChangeCLBState = async (e, value, clbId) => {
    let active = null;

    if (value == "Active") active = true;
    if (value == "In Active") active = false;
    dispatch(changeCLBStateAPI({ active, clbId }));
    dispatch(getAllCLBsAPI({ ...filters, sortFilters: sortFilter }));
  };

  const handleSearch = async (e) => {
    setSearchTxt(e.target.value);

    dispatch(
      getAllCLBsAPI({
        ...filters,
        sortFilters: sortFilter,
        category: category.id,
        title: e.target.value,
      })
    );
  };

  useEffect(() => {
    const getCLBs = async () => {
      await dispatch(getAllCLBsAPI({ ...filters }));
    };

    getCLBs();
  }, [filters]);

  useEffect(() => {
    dispatch(getAllCategoryAPI());
    dispatch(downlaodCLBsAPI());
    dispatch(getPriceSliderAPI());
  }, []);

  useEffect(
    () =>
      setFilters({
        ...filters,
        priceRange: [priceSliderResult?.min, priceSliderResult?.max],
      }),
    [priceSliderResult]
  );

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="p">CLBs</Typography>
            </Stack>
            <Box sx={{ display: "flex" }}>
              <Stack direction="row">
                <Stack marginRight={4} direction="row">
                  <input
                    onChange={handleSearch}
                    className={Style.searchBox}
                    placeholder="Search CLBs"
                    style={{ paddingLeft: "10px" }}
                  />
                  <button disabled className={Style.searchBtn}>
                    {" "}
                    <SearchOutlined />{" "}
                  </button>
                </Stack>
                <Button
                  onClick={handleFilterToggle}
                  className="whiteBtn"
                  endIcon={<FilterAltIcon />}
                >
                  Filter
                </Button>
                <Button
                  onClick={handleFilterToggleSort}
                  className="whiteBtn"
                  endIcon={<SortIcon />}
                >
                  Sort
                </Button>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                {/* <Button variant="outlined" onClick={() => navigate("/clbs/import")}>Create Clb</Button> */}
                {/* <Button variant="outlined" onClick={() => setRoyaltyFee(true)}>Royelty Fee</Button> */}
                <Button
                  variant="outlined"
                  onClick={() => navigate("/clbs/import")}
                >
                  Create Multiple Clbs
                </Button>
                <CSVLink
                  data={sampleCSV}
                  filename={`csv_file${new Date()}.csv`}
                >
                  <Button variant="outlined">
                    Download Sample Data
                    <CloudDownloadIcon sx={{ ml: 1 }} />
                  </Button>
                </CSVLink>
                <CSVLink data={downloadCLB}>
                  <Button variant="outlined">
                    Download Data <CloudDownloadIcon sx={{ ml: 1 }} />
                  </Button>
                </CSVLink>
              </Stack>
            </Box>
          </Stack>
        </Paper>

        <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row?._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>

      {/* filter menu */}
      <Menu
        anchorEl={anchorEl}
        open={openFilter}
        onClose={handleCloseFilter}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem id="topBid" onClick={handleExploreCLBsFilters}>
          Top Bids
        </MenuItem>
        <MenuItem>
          <Stack sx={{ width: "100%" }}>
            <Typography variant="p">Price Range</Typography>
            <Slider
              name="priceRange"
              value={filters?.priceRange}
              min={priceSliderResult?.min}
              max={priceSliderResult?.max}
              onChange={handleExploreCLBsFilters}
              fullWidth
              getAriaLabel={() => "Price Range"}
              valueLabelDisplay="auto"
            />
          </Stack>
        </MenuItem>

        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                {categories?.map((ele, idx) => {
                  return (
                    <FormControlLabel
                      checked={category.type === ele?.title}
                      key={idx}
                      value={ele?.title}
                      control={<Radio />}
                      label={ele?.title}
                      onChange={(event) =>
                        handleCatFilter(ele?.title, ele?._id)
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <MenuItem>
          <Stack spacing={1}>
            <Typography>Type</Typography>
            <RadioGroup onChange={handleExploreCLBsFilters}>
              <FormControlLabel
                name="bid"
                checked={typeCLB === "bid"}
                value="bid"
                control={<Radio />}
                label="Bid"
              />
              <FormControlLabel
                name="bid"
                checked={typeCLB === "purchase"}
                value="purchase"
                control={<Radio />}
                label="Purchase"
              />
            </RadioGroup>
          </Stack>
        </MenuItem>
      </Menu>

      {/* sortMenu */}
      <Menu
        anchorEl={anchorElSort}
        open={openFilterSort}
        onClose={handleCloseFilterSort}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleSortFilters("new")}
          className={sortFilter == "new" && Style?.activeFilterSort}
        >
          What’s new
        </MenuItem>
        <MenuItem
          onClick={() => handleSortFilters("alpha")}
          className={sortFilter == "alpha" && Style?.activeFilterSort}
        >
          Alphabetically
        </MenuItem>
        <MenuItem
          onClick={() => handleSortFilters("lowprice")}
          className={sortFilter == "lowprice" && Style?.activeFilterSort}
        >
          Price: Low to high
        </MenuItem>
        <MenuItem
          onClick={() => handleSortFilters("hightprice")}
          className={sortFilter == "hightprice" && Style?.activeFilterSort}
        >
          Price: High to low
        </MenuItem>
      </Menu>

      {openBidDetail && (
        <BidPriceDetailsModal
          open={openBidDetail}
          setOpen={setOpenBidDetail}
          Clb={selectedClb}
        />
      )}

      {mintCLB && (
        <ConfimeMintCLB
          open={mintCLB}
          setOpen={setMintCLB}
          CLB={CLBDetails}
          filters={filters}
          sortFilter={sortFilter}
          searchTxt={searchTxt}
        />
      )}

      {refundCLB && (
        <RefundWalletAddressModal
          open={refundCLB}
          setOpen={setMintCLB}
          handleSubmit={handleRejectCLB}
          setWalletAddress={setRefundWalletAddress}
        />
      )}
    </>
  );
}

export default CLBs;
