import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }


export const deleteBlogAPI = createAsyncThunk(
    'delete-Blogs',
    async(data, { rejectWithValue }) => {
        try {
            const response = await Axios.delete(`/blogs/delete/${data}`,)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



const counterSlice = createSlice({
    name: 'delete-Blogs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(deleteBlogAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteBlogAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(deleteBlogAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})


const deleteBlogAPIReducer = counterSlice.reducer

export default deleteBlogAPIReducer