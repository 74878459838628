import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addAdminAPI } from "../../redux/admin/addAdmin";
import { getAllAdminsAPI } from "../../redux/admin/getAllAdmins";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required(),
    email: yup.string().email("Invalid email address").required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required(),
    mobile: yup
      .string()
      .matches(/^\d{10}$/, "Mobile number should have only 10 digits")
      .required("Mobile number is required"),
    type: yup.string().required("Type is required"),
  })
  .required();

export default function AddAdmin({ open, setOpen }) {
  const addAdminSelector = useSelector((state) => state?.addAdmin);
  const { message, error } = addAdminSelector;

  const dispatch = useDispatch();

  const [banner, setBanner] = useState("");

  const [type, setType] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddAdmin = async (data) => {
    setSubmitting(true);
    const formData = { ...data };
    const created = await dispatch(addAdminAPI(formData));
    await dispatch(getAllAdminsAPI({ sort: "Newly Registered" }));
    setBanner("");
    if (!created?.payload?.error) {
      setOpen(false);
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBanner("");
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal" style={{ alignItems: "initial" }}>
          <form onSubmit={handleSubmit(handleAddAdmin)}>
            <Card sx={{ p: 2, width: 600 }}>
              <Stack spacing={2}>
                <Typography variant="h6">Add Admin</Typography>
                <TextField
                  multiline
                  label="Name *"
                  {...register("name")}
                  error={errors?.name?.message}
                  helperText={errors?.name?.message}
                />
                <TextField
                  multiline
                  label="Mobile Number *"
                  {...register("mobile")}
                  error={errors?.mobile?.message}
                  helperText={errors?.mobile?.message}
                />
                <TextField
                  multiline
                  label="Email *"
                  {...register("email")}
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                />
                <TextField
                  multiline
                  label="Password *"
                  {...register("password")}
                  error={errors?.password?.message}
                  helperText={errors?.password?.message}
                />

                <FormControl fullWidth error={!!errors?.type?.message}>
                  <InputLabel id="type-select-label">Select Type *</InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        labelId="type-select-label"
                        id="type-select"
                        {...field}
                        label="Select Type *"
                      >
                        <MenuItem value="Individual">Individual</MenuItem>
                        <MenuItem value="Organization">Organization</MenuItem>
                      </Select>
                    )}
                  />
                  {errors?.type?.message && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginLeft: "0.8rem" }}
                    >
                      {errors?.type?.message}
                    </Typography>
                  )}
                </FormControl>

                <Stack direction="row" spacing={2}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    variant="contained"
                  >
                    Create
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </form>
        </Box>
      </Modal>
    </>
  );
}
