import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImg from "../assets/png/notfoundImg1.jpg";
import Style from "../styles/notFound.module.scss";

export default function NotFoundpage() {
    const navigate = useNavigate()
    return (

        <Box className={Style?.main}>
            <Card sx={{ w: '30%', borderRadius: 10 }}>
                <Stack alignItems='center' spacing={2}>
                    <img src={NotFoundImg} alt='not found image' width={200} />
                    <Typography variant="h3">Whoops! Lost in Space?</Typography>
                    <Stack alignItems='center'>
                        <Typography variant="p">{`The page you looking for is'nt found :(`}</Typography>
                        <Typography variant="p">We suggest you back to home</Typography>
                    </Stack>
                    <Button variant='contained' startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate("/")}>Back to Home</Button>
                </Stack>
            </Card>
        </Box>
    )
}
