import { yupResolver } from "@hookform/resolvers/yup";
import { Label } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addBlogAPI } from "../../redux/Blog/AddBlog";
import { getAllBlogsAPI } from "../../redux/Blog/getAllBlogs";
import { getAllBlogsCategoryAPI } from "../../redux/BlogCategory/getAllBlogsCategory";
import { addBlogCategoryAPI } from "../../redux/BlogCategory/addBlogCategory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const addBlogCategorySchema = yup.object({
  title: yup.string().required("required field"),
});

const AddBlogCategory = ({ checked, setChecked }) => {
  const getAllBlogsCategorySelector = useSelector(
    (state) => state?.getAllBlogsCategory
  );
  const { result, loading } = getAllBlogsCategorySelector;

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [categoryId, setCategoryId] = useState(null);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addBlogCategorySchema),
  });

  const handleAddBlog = async (data) => {
    data.category = categoryId;
    console.log(data);
    setOpen(false);
    dispatch(addBlogCategoryAPI(data));
    // dispatch(getAllBlogsCategoryAPI());
    setTimeout(() => {
      setChecked(!checked);
    },2000);
    reset();
  };

  
  return (
    <>
      <div>
        <Button variant="contained" onClick={handleOpen}>
          Add New Blog Category
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(handleAddBlog)}>
              <Typography mb={2}> Add New Blog Category</Typography>
              <Stack spacing={2}>
                <TextField
                  label="Title"
                  {...register("title")}
                  error={errors?.title?.message}
                  helperText={errors?.title?.message}
                />
              </Stack>
              <Stack
                mt={2}
                spacing={2}
                direction={"row"}
                justifyContent={"flex-end"}
              >
                <Button onClick={handleClose} variant="contained">
                  Exit
                </Button>
                <Button type="submit" variant="contained">
                  Add
                </Button>
              </Stack>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AddBlogCategory;
