import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, CircularProgress, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCLbDetailsAPI } from "../../redux/clb/getCLbDetails";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";



function ClbDetails(props) {

  const getCLbDetailsSelector = useSelector(state => state?.getCLbDetails)
  const { result, loading } = getCLbDetailsSelector

  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const clbId = pathname?.split("/")[2]

  const navigate = useNavigate();


  useEffect(() => {
    dispatch(getCLbDetailsAPI(clbId))
  }, []);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' onClick={()=>{navigate("/clbs")}}>
              <IconButton><ArrowBackIosIcon /></IconButton>
              <Typography>CLBs Details</Typography>
            </Stack>
          </Stack>
        </Paper >


        <Grid container>
          <Grid item lg={12} md={12}>
            <Paper sx={{ p: 3 }}>
              {loading
                ? <CircularProgress />
                :
                <>
                  <Stack direction='row' spacing={2}>
                    {result?.CLBs?.type == '2d' && <Box sx={{ p: 4, width: 150 }} ><img src={result?.CLBs?.files?.[0]} width={'100%'} /></Box >}
                    {result?.CLBs?.type == '3d' &&
                      <model-viewer
                        style={{ width: '100%', height: '200px' }}
                        src={result?.CLBs?.files?.[0]}
                        ar-modes="webxr scene-viewer quick-look"
                        camera-controls
                        poster="poster.webp"
                        shadow-intensity="1"
                        disable
                      ></model-viewer>}
                    <Stack spacing={1}>
                      <Typography variant="h4">{result?.CLBs?.title}</Typography>
                      <Stack direction='row' spacing={1} alignItems={'center'}>
                        <Typography color='primary'>Status</Typography>
                        <Typography>{result?.CLBs?.status}</Typography>
                      </Stack>
                      <Typography>{result?.CLBs?.description}</Typography>
                      <Typography >{result?.CLBs?.files?.[0]}</Typography>
                    </Stack>
                  </Stack>
                </>
              }
            </Paper>
          </Grid>
        </Grid>

      </Body >
    </>
  );
}

export default ClbDetails;
