import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const genrateCLBDetailsAPI = createAsyncThunk(
    'genrate-clb-details',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`/nft/details`, data)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'genrate-clb-details',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(genrateCLBDetailsAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(genrateCLBDetailsAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(genrateCLBDetailsAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const genrateCLBDetailsReducer = counterSlice.reducer

export default genrateCLBDetailsReducer

