import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getCurrentAdminAPI } from "../redux/users/getCurrentAdmin";
import NotFoundpage from "../screens/NotFoundpage";
import Assets from "../screens/assets/Assets";
import Login from "../screens/auth/Login";
import Logout from "../screens/auth/Logout";
import Category from "../screens/category/Category";
import BulkClbUploader from "../screens/clbs/BulkClbUploader";
import ClbDetails from "../screens/clbs/ClbDetails";
import CLBs from "../screens/clbs/Clbs";
import Ticker from "../screens/tickers/Ticker";
import Dashboard from "../screens/dashboard/Dashboard";
import Gallery from "../screens/gallery/Gallery";
import Notifications from "../screens/notifications/Notifications";
import Orders from "../screens/order/Orders";
import Permission from "../screens/permissions/Permission";
import AboutUs from "../screens/resources/AboutUs";
import PrivacyPolicy from "../screens/resources/PrivacyPolicy";
import SocialMedia from "../screens/resources/SocialMedia";
import TermsCondition from "../screens/resources/TermsCondition";
import SLBs from "../screens/slb/SLBs";
import AdminUsers from "../screens/users/AdminUsers";
import Users from "../screens/users/Users";
import ProtectedRoute from "./ProtectedRoute";
import OrganizationDetails from "../screens/organization/OrganizationDetails";
import Organization from "../screens/organization/Organization";
import Banner from "../screens/banner/Banner";
import Feedback from "../screens/feeback/Feedback";
import ServerLoader from "../common/ServerLoader";
import Ticket from "../screens/tickets/Ticket";
import Blog from "../screens/Blogs/Blog";
import BlogCategory from "../screens/BlogsCategory/BlogCategory";

import AssignSLBsTable from "../screens/slb/AssignSLBsTable";
import ApiFailuresTable from "../screens/apiFailures/ApiFailuresTable";

function MainRoutes() {
  const dispatch = useDispatch();
  const getCurrentAdminSelector = useSelector(
    (state) => state?.getCurrentAdmin
  );
  const { result, loading } = getCurrentAdminSelector;

  const permissions = result?.permissionId?.permissions;
  const role = result?.role;

  useEffect(() => {
    if (localStorage.getItem("accessToken")) dispatch(getCurrentAdminAPI());
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          {(role == "SuperAdmin" || permissions?.includes("AdminUsers")) && (
            <Route
              path="/users/admin"
              element={
                <ProtectedRoute>
                  <AdminUsers /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Permissions")) && (
            <Route
              path="/users/permissions"
              element={
                <ProtectedRoute>
                  <Permission /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Users")) && (
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <Users /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Assets")) && (
            <Route
              path="/assets"
              element={
                <ProtectedRoute>
                  <Assets /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("CLBs")) && (
            <Route
              path="/clbs"
              element={
                <ProtectedRoute>
                  <CLBs /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("CLBs")) && (
            <Route
              path="/clbs/import"
              element={
                <ProtectedRoute>
                  <BulkClbUploader /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("CLBs")) && (
            <Route
              path="/clbs/:id/details"
              element={
                <ProtectedRoute>
                  <ClbDetails /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Orders")) && (
            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <Orders /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Gallery")) && (
            <Route
              path="/gallery"
              element={
                <ProtectedRoute>
                  <Gallery /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Category")) && (
            <Route
              path="/category"
              element={
                <ProtectedRoute>
                  <Category /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Organization")) && (
            <Route
              path="/organization"
              element={
                <ProtectedRoute>
                  <Organization /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Organization")) && (
            <Route
              path="/organization/:id"
              element={
                <ProtectedRoute>
                  <OrganizationDetails /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Banner")) && (
            <Route
              path="/banner"
              element={
                <ProtectedRoute>
                  <Banner /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("SLBs")) && (
            <Route
              path="/slbs"
              element={
                <ProtectedRoute>
                  <SLBs /> 
                </ProtectedRoute>
              }
            />
          )}

          {/* TEMP: To be removed */}
          {(role == "SuperAdmin" || permissions?.includes("Assign SLBs")) && (
            <Route
              path="/assign-slbs"
              element={
                <ProtectedRoute>
                  <AssignSLBsTable /> 
                </ProtectedRoute>
              }
            />
          )}

          {(role == "SuperAdmin" || permissions?.includes("API Failures")) && (
            <Route
              path="/api-failures"
              element={
                <ProtectedRoute>
                  <ApiFailuresTable /> 
                </ProtectedRoute>
              }
            />
          )}

          {(role == "SuperAdmin" || permissions?.includes("SLBs")) && (
            <Route
              path="/ticker"
              element={
                <ProtectedRoute>
                  <Ticker /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Feedback")) && (
            <Route
              path="/feedback"
              element={
                <ProtectedRoute>
                  <Feedback /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" ||
            permissions?.includes("PushNotification")) && (
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("AboutUs")) && (
            <Route
              path="/about-us"
              element={
                <ProtectedRoute>
                  <AboutUs /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" ||
            permissions?.includes("SocialMediaLinks")) && (
            <Route
              path="/social-media"
              element={
                <ProtectedRoute>
                  <SocialMedia /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" ||
            permissions?.includes("TermsConditions")) && (
            <Route
              path="/terms-conditions"
              element={
                <ProtectedRoute>
                  <TermsCondition /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("PrivacyPolicy")) && (
            <Route
              path="/privacy-policy"
              element={
                <ProtectedRoute>
                  <PrivacyPolicy /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Tickets")) && (
            <Route
              path="/tickets"
              element={
                <ProtectedRoute>
                  {" "}
                  <Ticket /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("Blogs")) && (
            <Route
              path="/blogs"
              element={
                <ProtectedRoute>
                  {" "}
                  <Blog /> 
                </ProtectedRoute>
              }
            />
          )}
          {(role == "SuperAdmin" || permissions?.includes("BlogsCategory")) && (
            <Route
              path="/blogsCategory"
              element={
                <ProtectedRoute>
                  {" "}
                  <BlogCategory /> 
                </ProtectedRoute>
              }
            />
          )}
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Login />} />

          {loading ? (
            <Route path="*" element={<ServerLoader />} />
          ) : (
            <Route path="*" element={<NotFoundpage />} />
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MainRoutes;
