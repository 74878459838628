import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios";

const initialState = { loading: false, result: [], error: null, message: "" };

export const assignSlbsToUserAPI = createAsyncThunk(
  "assign-slbs-to-user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`/slbs/assign-to-user`, data);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "assign-slbs-to-user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(assignSlbsToUserAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(assignSlbsToUserAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(assignSlbsToUserAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const assignSlbstoUserReducer = counterSlice.reducer;

export default assignSlbstoUserReducer;
