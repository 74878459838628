import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalCLBsAPI } from "../../redux/clb/getTotalCLBs";
import { getConfigMetaAPI } from "../../redux/config/getConfigMeta";
import { totalTransectionAPI } from "../../redux/payments/totalTransection";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import ClbGrowthRate from "./ClbGrowthRate";
import ClbSolds from "./ClbSolds";
import Axios from "../../config/authAxios";

function Dashboard(props) {
  const dispatch = useDispatch();
  const getTotalCLBsSelector = useSelector((state) => state?.getTotalCLBs);
  const { result: totalCLBs, loading: totalCLBsLoaing } = getTotalCLBsSelector;
  const totalTransectionSelector = useSelector(
    (state) => state?.totalTransection
  );
  const { result: totalTransection, loading: totalTransectionLoading } =
    totalTransectionSelector;
  const getConfigMetaSelector = useSelector((state) => state?.getConfigMeta);
  const { result: getConfigMeta, loading: getConfigMetaLoading } =
    getConfigMetaSelector;
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    dispatch(getTotalCLBsAPI());
    dispatch(totalTransectionAPI());
    dispatch(getConfigMetaAPI());
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    const { data } = await Axios.get("/admin/dashboardData");
    console.log(data.result);
    setDashboardData(data.result);
  };

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Stack direction="row" alignItems="center">
            <Typography>Overview</Typography>
          </Stack>
        </Paper>

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#D9EDD4" }}>
              {totalCLBsLoaing ? (
                <CircularProgress />
              ) : (
                <Typography variant="h4" fontWeight={600}>
                  {totalCLBs || "--"}
                </Typography>
              )}
              <Typography>New CLBs Created</Typography>
            </Paper>
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#F6DEDD" }}>
              {getConfigMetaLoading ? (
                <CircularProgress />
              ) : (
                <Typography variant="h4" fontWeight={600}>
                  {dashboardData?.bidPlaced || "--"}
                </Typography>
              )}
              <Typography>Bid Placed</Typography>
            </Paper>
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#DDDFF2" }}>
              {getConfigMetaLoading ? (
                <CircularProgress />
              ) : (
                <Typography variant="h4" fontWeight={600}>
                  {dashboardData?.clbBought || "--"}
                </Typography>
              )}
              <Typography>CLBs Bought</Typography>
            </Paper>
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#F0D9F3" }}>
              {totalTransectionLoading ? (
                <CircularProgress />
              ) : (
                <Typography variant="h4" fontWeight={600}>
                  {dashboardData?.transactions || "--"}
                </Typography>
              )}
              <Typography>Transactions</Typography>
            </Paper>
          </Grid>
        </Grid>

        <ClbSolds />
        {/* <ClbGrowthRate /> */}
      </Body>
    </>
  );
}

export default Dashboard;
