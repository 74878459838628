import { Box, Button, Card, Modal, Stack, Typography } from "@mui/material";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotificationAPI } from "../../redux/notification/deleteNotification";
import { getAllAdminNotificationAPI } from "../../redux/notification/getAllAdminNotification";



export default function DeleteNotification({ open, setOpen, notifyId }) {
    const deleteCatSelector = useSelector(state => state?.deleteCat)
    const { message, error } = deleteCatSelector

    const dispatch = useDispatch()



    const handleDeleteCategory = async () => {
        await dispatch(deleteNotificationAPI(notifyId))
        await dispatch(getAllAdminNotificationAPI())
        setOpen(false)

    }

    const handleClose = () => setOpen(false)


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 2, width: 600 }}>

                        <Stack spacing={2}>
                            <Typography variant='h6'>Delete Notification!</Typography>

                            <Stack direction='row' spacing={2}>
                                <Button onClick={handleDeleteCategory} type='submit' variant="contained">Delete</Button>
                                <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                            </Stack>
                        </Stack>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

