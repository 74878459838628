import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress, IconButton, Paper, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../ui/SideBar";
import Body from "../../ui/Body";
import Appbar from "../../ui/Appbar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getAllBlogsCategoryAPI } from "../../redux/BlogCategory/getAllBlogsCategory";
import { deleteBlogCategoryAPI } from "../../redux/BlogCategory/deleteBlogCategory";
import EditBlogCategory from "./EditBlogCategory";
import AddBlogCategory from "./AddBlogCategory";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};




const BlogCategory = () => {
 
  const getAllBlogsCategoryAPISelector = useSelector((state) => state?.getAllBlogsCategory);
  const { result , loading } = getAllBlogsCategoryAPISelector;
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);
  const [category, setCategory] = useState({});


  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      headerClassName: "super-app-theme--header"
    },
    {
      field: "edit_category",
      headerName: "Edit Category",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="info"
              onClick={() => handleEditBlogCategory(params?.row)}
            >
               <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_category",
      headerName: "Delete Category",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleDeleteBlogCategory(params?.row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ]


  const handleDeleteBlogCategory = (categoryId) =>{ 
     dispatch(deleteBlogCategoryAPI(categoryId))
     setTimeout(() => {
      setChecked(!checked);
    },2000);
  }

  const handleEditBlogCategory = (category) => {
    setOpenEdit(true)
     setCategory(category)
  }
  

  useEffect(() => {
     dispatch(getAllBlogsCategoryAPI());
  },[checked])

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
            
              <Typography variant="p">Blogs Category</Typography>
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center">
              <AddBlogCategory checked={checked} setChecked={setChecked} />
            </Stack>
          </Stack>
        </Paper>


        <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
      </Body>
      { openEdit ? <EditBlogCategory checked={checked} setChecked={setChecked} setOpen={ setOpenEdit } open={ openEdit } category={ category }/> : null  };
    </>
  );
};

export default BlogCategory;
