import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const soldCLBsAPI = createAsyncThunk(
    'sold-clbs',
    async (data, { rejectWithValue }) => {

        console.log("data", data)

        try {
            const response = await Axios.get(`/clbs/sold?${data}`)
            console.log(response.data);
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'sold-clbs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(soldCLBsAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(soldCLBsAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(soldCLBsAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const soldCLBsReducer = counterSlice.reducer

export default soldCLBsReducer

