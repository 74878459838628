import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import EditIcon from "@mui/icons-material/Edit";
import { getSingleBlogAPI } from "../../redux/Blog/getSingleBlog";
import { editBlogAPI } from "../../redux/Blog/editBlog";
import { getAllBlogsAPI } from "../../redux/Blog/getAllBlogs";
import { getSingleBlogCategoryAPI } from "../../redux/BlogCategory/getSingleBlogCategory";
import { editBlogCategoryAPI } from "../../redux/BlogCategory/editBlogCategory";
import { getAllBlogsCategoryAPI } from "../../redux/BlogCategory/getAllBlogsCategory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const addBlogSchema = yup.object({
  title: yup.string().required("required field"),
});

const EditBlogCategory = ({ setOpen, open, category, checked, setChecked }) => {
  const getSingleBlogCategoryAPISelector = useSelector(
    (state) => state?.getSingleBlogCategory
  );
  const { result, loading } = getSingleBlogCategoryAPISelector;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addBlogSchema),
  });

  const handleClose = () => setOpen(false);

  const handleEditBlog = async (data) => {
    data.categoryId = category?._id;
    dispatch(editBlogCategoryAPI(data));
    setOpen(false);
    setTimeout(() => {
      setChecked(!checked);
    }, 1000);

  };

  useEffect(() => {
    dispatch(getSingleBlogCategoryAPI(category?._id));
  }, []);

  useEffect(() => {
    console.log("result", result);
    let defaultValues = {};
    defaultValues = {
      title: result?.title,
    };

    reset({ ...defaultValues });
  }, [result]);

  return (
    <>
      <div>
        <IconButton variant="contained">
          <EditIcon />
        </IconButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(handleEditBlog)}>
              <Typography mb={2}>Edit Blog Category</Typography>
              <Stack spacing={2}>
                <TextField
                  label="Title"
                  {...register("title")}
                  error={errors?.title?.message}
                  helperText={errors?.title?.message}
                />
              </Stack>
              <Stack
                mt={2}
                spacing={2}
                direction={"row"}
                justifyContent={"flex-end"}
              >
                <Button onClick={handleClose} variant="contained" color="warning" >
                  Close
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Stack>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EditBlogCategory;
