import axios from "axios";
import { toast } from "react-hot-toast";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});

instance.interceptors.request.use(
  function (config) {
    config["headers"]["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response?.config?.method !== "get" && response.status !== 204)
      if (response?.data?.message)
        toast?.success(response?.data?.message, {
          position: "top-right",
        });
    return response;
  },
  function (error) {
    toast?.error(error?.response?.data?.message, {
      position: "top-right",
    });
    return Promise.reject(error);
  }
);

export default instance;
