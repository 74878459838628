import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Avatar, Button, Card, IconButton, Paper, Stack, Typography } from "@mui/material";
import { default as React, useState } from "react";
import { useCSVReader } from 'react-papaparse';
import { useDispatch, useSelector } from "react-redux";
import { uploadMultipleCLbsAPI } from "../../redux/clb/uploadMultipleCLbs";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import { useNavigate } from "react-router-dom";



const BulkClbUploader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const uploadMultipleCLbsSelector = useSelector(state => state?.uploadMultipleCLbs)
  const { message, error } = uploadMultipleCLbsSelector
  const { CSVReader } = useCSVReader();


  const [clbs, setClbs] = useState()
  const [snack, setSnack] = useState(false)

  const handleClbsUpload = async () => {
    await dispatch(uploadMultipleCLbsAPI(clbs))

  }

  return (

    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Stack direction='row' alignItems='center'>
            <IconButton onClick={()=>navigate('/clbs')}><ArrowBackIosIcon /></IconButton>
            <Typography>CLBs</Typography>
          </Stack>
        </Paper>

        <Paper sx={{ p: 4, my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Stack alignItems='center' spacing={2}>
            <CSVReader
              onUploadAccepted={(results) => {
                setClbs(results)
              }}
            >{({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, }) => (
              <>
                <Avatar sx={{ width: 100, height: 100 }}  {...getRootProps()}><CloudUploadIcon fontSize="large" /></Avatar>
                {acceptedFile && <IconButton sx={{ bgcolor: "red", color: '#fff' }} {...getRemoveFileProps()} ><ClearIcon /></IconButton>}
                <ProgressBar />
              </>
            )}
            </CSVReader>
            <Typography>CSV file Only</Typography>
            <Typography>Max size: 100 MB</Typography>
          </Stack>
          <Button disabled={!clbs?.data?.length} onClick={handleClbsUpload} fullWidth sx={{ my: 2 }} variant="contained">Upload</Button>
        </Paper>
      </Body >
    </>
  );
};

export default BulkClbUploader;
