import { CircularProgress, Stack } from '@mui/material'
import React from 'react'

export default function ServerLoader() {
    return (
        <Stack alignItems='center' justifyContent='center' height={'100vh'}>
            <CircularProgress />
        </Stack>
    )
}
