import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTicketsAPI } from "../../redux/tickets/getAllTickets";
import { getAllOrgAPI } from "../../redux/orgnisation/getAllOrg";
import { getCurrentAdminAPI } from "../../redux/users/getCurrentAdmin";
import { getCurrentUserOrgsAPI } from "../../redux/orgnisation/getCurrentUserOrgs";
import AddOrganization from "./AddOrganization";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import { chagneTicketStatusAPI } from "../../redux/tickets/chagneTicketStatus";

import moment from "moment";
import { changeOrgStatusAPI } from "../../redux/orgnisation/changeOrgStatus";
import DisplayOrgDocumentsModal from "./DisplayOrgDocumentsModal";

function Organization() {
  // const getAllTicketsSelector = useSelector((state) => state?.getAllTickets);
  const getAllOrgSelector = useSelector((state) => state?.getAllOrg);
  const getCurrentAdminSelector = useSelector(
    (state) => state?.getCurrentAdmin
  );
  const getCurrentUserOrgsSelector = useSelector(
    (state) => state?.getCurrentUserOrgs
  );

  const dispatch = useDispatch();

  const adminUser = getCurrentAdminSelector.result;
  // console.log(adminUser._id);

  useEffect(() => {
    // dispatch(getAllTicketsAPI());
    dispatch(getCurrentAdminAPI());
    if (adminUser.role === "SuperAdmin") dispatch(getAllOrgAPI());
    else dispatch(getCurrentUserOrgsAPI());
  }, []);

  let dataResult;
  let isLoading;
  if (adminUser.role === "SuperAdmin") {
    const { result, loading } = getAllOrgSelector;
    dataResult = result;
    isLoading = loading;
  } else {
    const { result, loading } = getCurrentUserOrgsSelector;
    dataResult = result;
    isLoading = loading;
  }

  const [addOrganization, setAddOrganization] = useState(false);
  const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
  const [currentDoc, setCurrentDoc] = useState();

  const handleStatusChange = async (e, value, orgId) => {
    await dispatch(changeOrgStatusAPI({ orgId, status: value }));
    // dispatch(getAllTicketsAPI());
  };

  let columns = [
    // {
    //   field: "legalName",
    //   headerName: "legalName",
    //   width: 250,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "country",
      headerName: "Country",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "slbRequired",
      headerName: "SLBs Required",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "documents",
      headerName: "Documents",
      width: 250,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.documents && params?.row?.documents.length !== 0) {
          return (
            <>
              <Button
                onClick={() => {
                  const currUser = dataResult.find(
                    (usr) => usr?._id === params?.row?._id
                  );

                  const userDocuments = currUser ? currUser?.documents : [];
                  setCurrentDoc(userDocuments);
                  setOpenDocumentsModal(true);
                }}
                startIcon={<VisibilityIcon fontSize="small" />}
                variant="outlined"
                size="small"
              >
                View
              </Button>
            </>
          );
        } else {
          return <Typography variant="body2">Not uploaded</Typography>;
        }
      },
    },
    // {
    //   field: "identifyProof",
    //   headerName: "identifyProof",
    //   width: 250,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Box sx={{ p: 4, width: 150 }}>
    //           <img src={params?.row?.identifyProof} width={"100%"} />
    //         </Box>
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "approve_rejected_status",
    //   headerName: "Approved / Rejected",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params?.row?.status == "Approved" && (
    //           <Typography variant="p" color="primary">
    //             Approved
    //           </Typography>
    //         )}
    //         {params?.row?.status == "Rejected" && (
    //           <Typography variant="p" color="error">
    //             Disabled
    //           </Typography>
    //         )}
    //         {params?.row?.status == "Pending" && (
    //           <Typography variant="p" sx={{ color: "#ffc107" }}>
    //             Pending
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "approve_rejected",
      headerName: "Approved / Rejected",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Autocomplete
              fullWidth
              style={{ margin: ".5rem" }}
              size="small"
              defaultValue={params?.row?.status}
              onChange={(e, value) =>
                handleStatusChange(e, value, params?.row?._id)
              }
              disablePortal
              options={["Pending", "Approved", "Rejected"]}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
             
              <Typography variant="p">Organization</Typography>
            </Stack>

            {adminUser.role === "SuperAdmin" && (
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    variant="outlined"
                    onClick={() => setAddOrganization(true)}
                  >
                    Add Organization
                  </Button>
                </Stack>
              </Box>
            )}
          </Stack>
        </Paper>

        <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={dataResult}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={isLoading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>
      {addOrganization && (
        <AddOrganization open={addOrganization} setOpen={setAddOrganization} />
      )}
      {openDocumentsModal && (
        <DisplayOrgDocumentsModal
          files={currentDoc}
          open={openDocumentsModal}
          setOpen={setOpenDocumentsModal}
        />
      )}
    </>
  );
}

export default Organization;
