import React, { useState, useEffect, useCallback } from "react";
import { IconButton, Paper, Button, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Axios from "../../config/authAxios";
import { DataGrid } from "@mui/x-data-grid";
import { RxCross2 } from "react-icons/rx";

import HeadingBar from "../../components/common/HeadingBar";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import DeleteTicker from "./DeleteTicker";
import EditTicker from "./EditTicker";

function Ticker() {
  const [newTicker, setNewTicker] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [editTicker, setEditTicker] = useState("");

  const [delOpen, setDelOpen] = useState(false);
  const [delTickerId, setDelTickerId] = useState("");

  const [tickers, setTickers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [states, setStates] = useState({});

  const handleCreateNewTicker = async (e) => {
    setTempLoading(true);
    e.preventDefault();
    await Axios.post("tickers/create", { ticker: newTicker, createdBy: "" });
    fetchData();
    setShowModal(!showModal);
    setTempLoading(false);
  };

  const handleEdit = (data) => {
    setEditOpen(true);
    setEditTicker(data);
  };
  const handleEditTicker = async (updatedTicker, _id) => {
    try {
      await Axios.put(`/tickers/${_id}`, { ticker: updatedTicker });
      fetchData();
    } catch (error) {
      console.error("Error deleting ticker:", error);
    } finally {
      setDelOpen(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await Axios.get("/tickers");
      setLoading(false);
      setTickers(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handelDeleteTicker = useCallback((tickerId) => {
    setDelOpen(true);
    setDelTickerId(tickerId);
  }, []);

  const handleConfirmedDelete = async () => {
    try {
      await Axios.delete(`/tickers/${delTickerId}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting ticker:", error);
    } finally {
      setDelOpen(false);
    }
  };

  let columns = [
    {
      field: "ticker",
      headerName: "Ticker",
      width: 800,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "edit_category",
      headerName: "Edit Ticker",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton color="info" onClick={() => handleEdit(params?.row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_Ticker",
      headerName: "Delete Ticker",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handelDeleteTicker(params?.row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        {showModal && (
          <form
            className="h-full w-full inset-0 flex justify-center shadow-xl  items-center fixed bg-black/50 z-50"
            onSubmit={handleCreateNewTicker}
          >
            <div className="bg-white flex flex-col p-4 w-96 h-fit rounded-lg">
              <div className="flex w-full justify-end text-2xl text-[#999999] ">
                <RxCross2
                  className="cursor-pointer"
                  onClick={() => {
                    setStates({ title: "" });
                    setShowModal(false);
                  }}
                />
              </div>
              {/* <ImageUploader image={files} onChange={imageHandler} /> */}
              <div className="py-2 h-fit">
                <label htmlFor="newTicker">New Ticker</label>
                <input
                  type="text"
                  id="newTicker"
                  name="newTicker"
                  value={newTicker}
                  onChange={(e) => setNewTicker(e.target.value)}
                  className="p-2 rounded-md border border-solid w-full h-full"
                  placeholder="Title"
                />
              </div>
              {
                tempLoading ?
                <CircularProgress /> :
                <Button type="submit">Save</Button>
              }
            </div>
          </form>
        )}
          <HeadingBar
            title="Ticker"
            element={
              <div className="w-full gap-4 justify-end mx-2 h-full flex">
              {tickers && tickers.length < 1 && (
                <button
                  onClick={() => setShowModal(!showModal)}
                  className="primary-btn flex gap-3 items-center py-2 px-4 rounded-lg font-bold"
                >
                  Add New Ticker
                </button>
                )}
              </div>
            }
          />

        <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={tickers}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>
      {editOpen && (
        <EditTicker
          open={editOpen}
          setOpen={setEditOpen}
          data={editTicker}
          editFun={handleEditTicker}
        />
      )}
      {delOpen && (
        <DeleteTicker
          open={delOpen}
          setOpen={setDelOpen}
          // tickerId={delTickerId}
          deleteFun={handleConfirmedDelete}
        />
      )}
    </>
  );
}

export default Ticker;
