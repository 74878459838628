import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios";

const initialState = {
  loading: false,
  result: { min: 1, max: 100000 },
  error: null,
  message: "",
};

export const getPriceSliderAPI = createAsyncThunk(
  "get-price-slider",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/explore/slider-range`);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const priceSliderSlice = createSlice({
  name: "get-price-slider",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPriceSliderAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPriceSliderAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(getPriceSliderAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const getPriceSliderReducer = priceSliderSlice.reducer;

export default getPriceSliderReducer;
