import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDate } from "../../helpers/date.helper";
import { getAllAdminNotificationAPI } from "../../redux/notification/getAllAdminNotification";
import { pushNotificationAPI } from "../../redux/notification/pushNotification";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";

import CreateNotification from "./CreateNotification";
import EditNotification from "./EditNotification";
import DeleteNotification from "./DeleteNotification";

function Notifications() {
  const getAllAdminNotificationSelector = useSelector(
    (state) => state?.getAllAdminNotification
  );
  const { result, loading } = getAllAdminNotificationSelector;

  const pushNotificationSelector = useSelector(
    (state) => state?.pushNotification
  );
  const { message, error,loading:load } = pushNotificationSelector;

  const dispatch = useDispatch();

  const [create, setCreate] = useState(false);
  const [snack, setSnack] = useState(false);
  const [editNoti, setEditNoti] = useState(false);
  const [delNoti, setDelNoti] = useState(false);
  const [notifyId, setNotifyId] = useState("");

  const handlePushNotification = async (notificationId) => {
    dispatch(pushNotificationAPI({ notificationId }));
  };

  let columns = [
    {
      field: "message",
      headerName: "Message",
      width: 800,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "push",
      headerName: "Send Notification",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => handlePushNotification(params?.row?._id)}
              sx={{ my: 1, color: "#fff" }}
            >
              Push
            </Button>
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Creation Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Typography>{handleDate(params?.row?.created_at)}</Typography>;
      },
    },
    {
      field: "edit_notification",
      headerName: "Edit Notification",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="info"
              onClick={() => handleEditNotification(params?.row?._id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_notification",
      headerName: "Delete Notification",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleDeleteNotification(params?.row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleAddNotification = () => setCreate(true);

  const handleEditNotification = async (notifyId) => {
    setEditNoti(true);
    setNotifyId(notifyId);
  };

  const handleDeleteNotification = async (notifyId) => {
    setDelNoti(true);
    setNotifyId(notifyId);
  };

  useEffect(() => {
    dispatch(getAllAdminNotificationAPI());
  }, []);

  return (
    <>
      <SideBar />
   
        <Body>
          <Appbar />
          <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <Typography variant="p">Push Notifications</Typography>
              </Stack>
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button variant="outlined" onClick={handleAddNotification}>
                    Create Notification
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Paper>

          <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
            <DataGrid
              getRowId={(row) => row._id}
              getRowHeight={() => "auto"}
              rows={result}
              columns={columns}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
              loading={loading || load && <CircularProgress />}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
            />
          </Paper>
        </Body>


      {create && <CreateNotification open={create} setOpen={setCreate} />}
      {editNoti && (
        <EditNotification
          open={editNoti}
          setOpen={setEditNoti}
          notifyId={notifyId}
        />
      )}
      {delNoti && (
        <DeleteNotification
          open={delNoti}
          setOpen={setDelNoti}
          notifyId={notifyId}
        />
      )}
    </>
  );
}

export default Notifications;
