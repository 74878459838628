import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addTermsConditionsAPI } from '../../redux/resource/addTermsConditions';
import { getResourcesAPI } from "../../redux/resource/getResources";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import Snack from '../../ui/snack';


export default function TermsCondition() {
  const getResourcesSelector = useSelector(state => state?.getResources)
  const { result: resourcesData } = getResourcesSelector
  const addTermsConditionsSelector = useSelector(state => state?.addTermsConditions)
  const { message } = addTermsConditionsSelector
  const dispatch = useDispatch()

  const [snack, setSnack] = useState(false)

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm(
    { defaultValues: {} });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "termsConditions",
  });
  const [pointsVal, setPointsVal] = useState("")

  const handleAddPoints = () => {
    append({ points: pointsVal });
    setPointsVal("")
  }
  const handleDeletePoints = (item, idx) => {
    remove(idx)
  }

  const handleAddTermsCondtions = async (data) => {
    await dispatch(addTermsConditionsAPI({ termsCondtionId: resourcesData?._id, termsConditions: data?.termsConditions }))

  }


  useEffect(() => {
    const fetchResourse = async () => {
      const resourseApiData = await dispatch(getResourcesAPI())
      let defaultValues = {};
      defaultValues = { termsConditions: resourseApiData?.payload?.result?.termsConditions, }
      reset({ ...defaultValues });
    }
    fetchResourse()
  }, [dispatch])


  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Stack spacing={2}>
          <form onSubmit={handleSubmit(handleAddTermsCondtions)}>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Stack direction='row' alignItems='center'>
                
                <Typography>Terms & Conditions</Typography>
              </Stack>
            </Paper>
            <Paper sx={{ my: 2, p: 2, borderRadius: 2 }}>
              <Stack spacing={2} direction='row' sx={{ my: 2 }} alignItems='center'>
                <TextField multiline onChange={(event) => setPointsVal(event.target.value)} value={pointsVal} label="Points" />
                <IconButton disabled={!pointsVal} onClick={handleAddPoints} sx={{ bgcolor: 'primary.main' }}><AddIcon /></IconButton>
              </Stack>
              {fields?.map((item, idx) => {
                console.log(item?.points, idx);
                
                return (
                  <Stack key={idx} spacing={2} direction='row' sx={{ my: 2 }} alignItems='center'>
                    <TextField multiline {...register(`termsConditions.${idx}.value`)} disabled value={item?.points} />
                    <IconButton onClick={() => handleDeletePoints(item, idx)} sx={{ bgcolor: 'primary.error' }}><DeleteIcon sx={{ color: '#fff' }} /></IconButton>
                  </Stack>
                )
              })}
              <Button disabled={!fields?.length} sx={{ my: 2 }} type="submit" variant="contained">Add</Button>
            </Paper>
          </form>
        </Stack>
      </Body>
    </>
  );
}
