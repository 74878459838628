import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addUserAPI } from "../../redux/admin/addUser";
import { getAllUsersAPI } from "../../redux/users/getAllUsers";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required(),
    email: yup.string().email("Invalid email address").required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required(),
    mobile: yup
      .string()
      .matches(/^\d{10}$/, "Mobile number should have only 10 digits")
      .required("Mobile number is required"),
    // type: yup.string().required(),
  })
  .required();

export default function AddUser({ open, setOpen }) {
  const addUserSelector = useSelector((state) => state?.addUser);
  const { message, error } = addUserSelector;

  const [loading, setLoading] = useState(false);

  console.log(`addUserSelector: ${message}`);

  const dispatch = useDispatch();

  const [banner, setBanner] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddUser = async (data) => {
    setLoading(true);
    await dispatch(addUserAPI({ ...data, country: "India" }));
    setOpen(false);
    setLoading(false)
    await dispatch(getAllUsersAPI({ sort: "Newly Registered" }));
    setBanner("");
  };

  const handleClose = () => {
    setOpen(false);
    setBanner("");
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal" style={{ alignItems: "initial" }}>
          <form onSubmit={handleSubmit(handleAddUser)}>
            <Card sx={{ p: 2, width: 600 }}>
              <Stack spacing={2}>
                <Typography variant="h6">Add User</Typography>
                <TextField
                  multiline
                  label="Name"
                  {...register("name")}
                  error={errors?.name?.message}
                  helperText={errors?.name?.message}
                />
                <TextField
                  multiline
                  label="Mobile Number"
                  {...register("mobile")}
                  error={errors?.mobile?.message}
                  helperText={errors?.mobile?.message}
                />
                <TextField
                  multiline
                  label="Email"
                  {...register("email")}
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                />
                <TextField
                  multiline
                  label="Password"
                  {...register("password")}
                  error={errors?.password?.message}
                  helperText={errors?.password?.message}
                />

                <Stack direction="row" spacing={2}>
                  {
                    loading ? 
                    <CircularProgress /> :
                    <Button type="submit" variant="contained">
                      Create
                    </Button>
                  }
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </form>
        </Box>
      </Modal>
    </>
  );
}
